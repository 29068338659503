<template>
  <div class="login-container" v-if="isReload">
 <div style="width: 500px;
height: 468px;
background: #FFFFFF;
border-radius: 16px; display: flex;justify-content: center;align-items: center;">
  <el-form
     
      autocomplete="on"
      :model="loginForm"
      :rules="loginRules"
      ref="loginForm"
      label-position="left"
    >
      <!-- <div class="title">每选溯源管理系统</div> -->
      <div class="title">高邮大虾养殖溯源管理系统</div>

      <el-form-item prop="username" style="width: 410px;
border-radius: 4px;
">
        <el-input name="username" type="text" v-model="loginForm.username" placeholder="请输入用户名">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="password" style="width: 410px;

border-radius: 4px;
">
        <el-input
          name="password"
          :type="pwdType"
          @keyup.enter.native="login"
          v-model="loginForm.password"
          placeholder="请输入密码"
        >
          <i slot="prefix" @click="showPwd" class="el-input__icon el-icon-lock"></i>
          <i slot="suffix" @click="showPwd" class="el-input__icon el-icon-view"></i>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="checked">记住密码</el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          style="width: 410px;
height: 56px;
background: #00B38A;
border-radius: 4px;"
          :loading="loading"
          @click.native.prevent="login"
        >登录</el-button>
      </el-form-item>
    
    </el-form>
 </div>
  </div>
</template>
<script>
import { apiLogin } from "../request/http";
import { decrypt, encrypt } from "../util/JSEncrypt";
import { mapActions } from 'vuex' // 引入vuex的辅助函数
import Cookies from "js-cookie";
import axios from "axios";
export default {
  name: "login",
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("请输入正确的用户名"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("密码不能小于5位"));
      } else {
        callback();
      }
    };
    return {
      isReload: false,
      checked: false,
      loginForm: {
        username: "",
        password: ""
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: "blur",
            validator: validateUsername
          }
        ],
        password: [{ required: true, trigger: "blur", validator: validatePass }]
      },
      loading: false,
      pwdType: "password"
    };
  },
  created() {
    this.getCookie();
    // 重置vuex中的值
    // if(!sessionStorage.getItem('token')){
    //   return window.location.reload()
    // }
    this.isReload = true;
  },
  mounted() {},
  methods: {
    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "";
      } else {
        this.pwdType = "password";
      }
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      // console.log();
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password)
      };
      if (rememberMe) {
        this.checked = rememberMe == 1 ? true : false;
      } else [(this.checked = false)];
      rememberMe === undefined ? false : Boolean(rememberMe);
    },
    ...mapActions(['user/login']),
   async login() {
    try {
      this.loading = true
      let password = this.$md5(this.loginForm.password.trim());
      let postData = {
        account: this.loginForm.username.trim(),
        password: password
      };
      localStorage.setItem('account',postData.account)
     await this['user/login'](postData)
    if( localStorage.getItem('role')==3||localStorage.getItem('role')==4){
      this.$router.push('/audit')
    }  else if(localStorage.getItem('role')==1){
      this.$router.push('/Overview1')
    }
    else {
      this.$router.push('/area')
    }
     
    } catch (error) {
      this.$message.warning("账号密码不正确");
    }
    finally {
        //  不论执行try 还是catch  都去关闭转圈
        this.loading = false
      }
     
      
    },

  }
};
</script>
<style>
.login-container {
  background: url('.././assets/icon_beijing.png');
  height: 100vh;
  display: flex;justify-content: center;align-items: center;
}

.title {
  text-align: center;
  font-size: 35px;
  margin-bottom: 40px;
  font-weight: 600;
  
color: #4A4A4A;
line-height: 56px;
}
.login-container .el-checkbox__label {
  color: #d1cbcb;
}
</style>